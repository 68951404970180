import { defineStore } from 'pinia'

export const useArticlePage = defineStore('useArticlePage', () => {
  /**
   * ! Pinia State !
   *
   * @param announcePage 공지사항 페이지
   * @param eventPage 이벤트 페이지
   *
   * @param boardFreePage 자유게시판 페이지
   * @param boardQnaPage 질문게시판 페이지
   * @param boardIssuePage 사건사고 게시판 페이지
   * @param boardRoutePage 라이딩루트 게시판 페이지
   * @param boardVehicleCarePage 정비 유지보수 게시판 페이지
   * @param boardRidingPage 라이딩팁 게시판 페이지
   * @param boardTuningPage 구변/환검 게시판 페이지
   * @param boardGalleryPage 회원사진 게시판 페이지
   * @param boardReportTheftPage 도난제보 게시판 페이지
   * @param boardBusinessPage 업체홍보 게시판 페이지
   *
   * @param marketOver125ManualPage 125cc 이상 매뉴얼 마켓 페이지
   * @param marketOver125ScooterPage 125cc 이상 스쿠터 마켓 페이지
   * @param marketUnder125ManualPage 125cc 이하 매뉴얼 마켓 페이지
   * @param marketUnder125ScooterPage 125cc 이하 스쿠터 마켓 페이지
   * @param marketGearPage 안전장비 마켓 페이지
   * @param marketPartsPage 부품 마켓 페이지
   * @param marketDeliveryGearPage 배달장비 마켓 페이지
   * @param marketBuyPage 삽니다 마켓 페이지
   *
   * @param reviewMotorcycleListPage 모터사이클 리뷰 목록 페이지
   *
   */

  const announcePage = ref(1)
  const eventPage = ref(1)

  const boardFreePage = ref(1)
  const boardQnaPage = ref(1)
  const boardIssuePage = ref(1)
  const boardRoutePage = ref(1)
  const boardVehicleCarePage = ref(1)
  const boardRidingPage = ref(1)
  const boardTuningPage = ref(1)
  const boardGalleryPage = ref(1)
  const boardReportTheftPage = ref(1)
  const boardBusinessPage = ref(1)

  const marketOver125ManualPage = ref(1)
  const marketOver125ScooterPage = ref(1)
  const marketUnder125ManualPage = ref(1)
  const marketUnder125ScooterPage = ref(1)
  const marketGearPage = ref(1)
  const marketPartsPage = ref(1)
  const marketDeliveryGearPage = ref(1)
  const marketBuyPage = ref(1)

  const reviewMotorcycleListPage = ref(1)

  return {
    announcePage,
    eventPage,
    boardFreePage,
    boardQnaPage,
    boardIssuePage,
    boardRoutePage,
    boardVehicleCarePage,
    boardRidingPage,
    boardTuningPage,
    boardGalleryPage,
    boardReportTheftPage,
    boardBusinessPage,
    marketOver125ManualPage,
    marketOver125ScooterPage,
    marketUnder125ManualPage,
    marketUnder125ScooterPage,
    marketGearPage,
    marketPartsPage,
    marketDeliveryGearPage,
    marketBuyPage,
    reviewMotorcycleListPage,
  }
}, {
  persist: {
    storage: piniaPluginPersistedstate.sessionStorage(),
  },
})
